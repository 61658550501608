import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import "react-web-tabs/dist/react-web-tabs.css";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { RightArrowIconAssistants } from "../../components/common/Icons";
import {
  Onboarding_November,
  OnboardingMobile_November,
  HrItSupportMobile_November,
  HrItSupport_November,
  usePreloadImages_November,
} from "../conversational-ai-platform";
import { VideoElement2 } from "../../components/video";
import { AppsIntegrations } from "../conversational-ai-platform";
import { useMedia } from "use-media";
import { SliderWithWidthAdjusted } from "../../components/common/sliderIntegration";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import { useSwipeable } from "react-swipeable";


const topImage = require("../../assets/img/integration/bamboohr/em_header.png");

const LogoImg = require("../../assets/images/icons-web/rydoo.svg");

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");

const workflow_arrow = require("../../assets/img/home_page_assist/workflow_arrow.svg");
const arrow = require("../../assets/img/home_page_assist/arrow.svg");
const arrow_round_mobile = require("../../assets/img/home_page_assist/left_arrow_mobile.svg");

const actionImg = require("../../assets/images/icons-web/ci_workflow_icon.svg");

const bot_icon = require("../../assets/images/icons-web/ci_bot_icon.svg");

const ad_one = require("../../assets/img/home_page_assist/hr_ad_1.png");
const ad_two = require("../../assets/img/home_page_assist/hr_ad_2.png");
const ad_three = require("../../assets/img/home_page_assist/hr_ad_3.png");

const capabilities_1 = require("../../assets/img/home_page_assist/hr_capabilities_1.png");
const capabilities_2 = require("../../assets/img/home_page_assist/hr_capabilities_2.png");
const capabilities_4 = require("../../assets/img/home_page_assist/hr_capabilities_4.png");
const capabilities_5 = require("../../assets/img/home_page_assist/em_capabilities_5.png");
const capabilities_6 = require("../../assets/img/home_page_assist/hr_capabilities_6.png");
const capabilities_7 = require("../../assets/img/home_page_assist/hr_capabilities_7.png");
const capabilities_8 = require("../../assets/img/home_page_assist/hr_capabilities_8.png");
const capabilities_9 = require("../../assets/img/home_page_assist/hr_capabilities_9.png");
const capabilities_10 = require("../../assets/img/home_page_assist/hr_capabilities_10.png");
const capabilities_11 = require("../../assets/img/home_page_assist/hr_capabilities_11.png");
const capabilities_12 = require("../../assets/img/home_page_assist/hr_capabilities_12.png");
const capabilities_13 = require("../../assets/img/home_page_assist/hr_capabilities_13.png");

const capabilities_3_1 = require("../../assets/img/home_page_assist/em_capabilities_3_1.png");
const capabilities_3_2 = require("../../assets/img/home_page_assist/em_capabilities_3_2.png");
const capabilities_3_3 = require("../../assets/img/home_page_assist/em_capabilities_3_3.png");
const capabilities_3_4 = require("../../assets/img/home_page_assist/em_capabilities_3_4.png");
const capabilities_3_5 = require("../../assets/img/home_page_assist/em_capabilities_3_5.png");
const capabilities_3_6 = require("../../assets/img/home_page_assist/em_capabilities_3_6.png");

const onboard_one = require("../../assets/img/home_page_assist/4x_hr_onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/4x_hr_onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/4x_em_onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/4x_hr_onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/4x_hr_onboarding_5.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const box = require("../../assets/images/icons-web/box_1.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const sharePoint = require("../../assets/images/icons-web/sharepoint.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const twilio = require("../../assets/images/icons-web/twilio.svg");
const outlook = require("../../assets/img/integration/icons/Outlook.svg");

const SliderData = [];
const SingleSliderData = [
  {
    icon: bot_icon,
    heading: "HR Chatbot",
  },
  {
    icon: bot_icon,
    heading: "Rydoo Chatbot",
  },
];
const integrationsApps = [
  {
    header: "19+ ITSM",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow",
        url: "/conversational-ai-platform/servicenow-chatbot",
      },
      {
        icon: freshservice,
        appName: "Freshservice",
        url: "/conversational-ai-platform/freshservice-chatbot",
      },
      {
        icon: jira,
        appName: "Jira Service Desk",
        url: "/conversational-ai-platform/jira-service-desk-chatbot",
      },
      {
        icon: solarwinds,
        appName: "SolarWinds",
        url: "/conversational-ai-platform/solarwinds-chatbot",
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus",
        url: "/conversational-ai-platform/servicedesk-plus-chatbot",
      },
    ],
  },
  {
    header: "13+ HR",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
        url: "/conversational-ai-platform/bamboohr-chatbot",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
        url: "/conversational-ai-platform/orange-hrm-chatbot",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
        url: "/conversational-ai-platform/zoho-people-chatbot",
      },
      {
        icon: gusto,
        appName: "Gusto",
        url: "/conversational-ai-platform/gusto-chatbot",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
        url: "/conversational-ai-platform/sap-successfactors-chatbot",
      },
    ],
  },
  {
    header: "15+ Access Management",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },

  {
    header: "Productivity",
    userApps: [
      {
        icon: box,
        appName: "Box",
        url: "/conversational-ai-platform/box-chatbot",
      },
      {
        icon: dropbox,
        appName: "Dropbox",
        url: "/conversational-ai-platform/dropbox-chatbot",
      },
      {
        icon: sharePoint,
        appName: "SharePoint",
        url: "/conversational-ai-platform/sharepoint-chatbot",
      },
      {
        icon: outlook,
        appName: "Outlook",
        url: "/conversational-ai-platform/outlook-chatbot",
      },
      {
        icon: twilio,
        appName: "Twilio",
        url: "/conversational-ai-platform/twilio-chatbot",
      },
    ],
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Rydoo Chatbot Integration | How to build Rydoo chatbot and tasks using Conversational AI Platform"
        description="Learn how to build Rydoo chatbot in Slack and Teams using Conversational AI Platform. Automate expense claim, reimbursement, payment enquiry tasks using chatbot."
        keywords={["Rydoo"]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot chatbot_integration_left_container landing_page_chatbot support_chatbot_it_help chatbot_integration_parent_container ci_workflow_hidden">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"Rydoo chatbot with app workflow"}
              iD={"chatbot"}
              additionalClassImage={"w-90"}
            >
              <RegisterSection.FormWithContent>
                <div className="logo_and_soon">
                  <div className="logo_container_integration">
                    <RegisterSection.LogoImage>
                      {LogoImg}
                    </RegisterSection.LogoImage>
                  </div>
                  <div className="coming_soon_wrapper coming_soon_wrapper_integrations">
                    <span className="coming_soon_integrations">
                      Coming soon
                    </span>
                  </div>
                </div>
                <RegisterSection.MainHeader>
                  Rydoo chatbot with app workflows
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Automate expense claim, reimbursements, payment enquires, and
                  employee self-service with Gen AI Chatbot for Rydoo.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <div
              className={`float-left w-100 ${
                isSmall ? "mb-4 mt-4" : "mb-5 mt-5"
              }`}
            >
              <div className="container mb-2">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="  d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>

            {isSmall ? (
              <HrItSupportMobile_November
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"Rydoo Chatbot Capabilities"}
                isComingSoon={tabsForHrIt.isComingSoon}
              />
            ) : (
              <HrItSupport_November
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"Rydoo Chatbot Capabilities"}
                headerGroupByCountList={[4, 5, 4]}
                isComingSoon={tabsForHrIt.isComingSoon}
              />
            )}
            <div className="integrations_slider_bg">
              <SliderWithWidthAdjusted
                sliderData={SliderData}
                singleSliderDataMobile={SingleSliderData}
                additionalClass="color-light-blue"
                SliderHeader={"Pre-built templates for Rydoo"}
                title={"Rydoo Automations for Chatbot"}
                TopSliderTitle={"Chatbot templates for Rydoo"}
                TopSliderText={"HR Chatbot"}
                TopSliderSubText={"Rydoo Chatbot"}
                marketplaceTitle={"See all app workflow"}
                additionalClassForSlide=""
                cardWidthClass={"width-280"}
              />
            </div>
            {isSmall ? (
              <OnboardingMobile_November
                tabContent={tabsForOnBoarding}
                imagesToPreload={tabsForOnBoardingImages}
              />
            ) : (
              <Onboarding_November
                tabContent={tabsForOnBoarding}
                imagesToPreload={tabsForOnBoardingImages}
              />
            )}
            <AdHomePage_November />
            <RequestForm isFooterForm={true} />

            <AppsIntegrations
              additionalClass="margin-bottom-0"
              header={
                isSmall ? (
                  <h3 className="font-section-header mb-2 color-light-black">
                    Connect Rydoo Chatbot with 100+apps, and 1000s of
                    automations instantly.
                    <span className="font-text-medium-apps-ci color-light-grey">
                      {" "}
                      No coding
                    </span>
                  </h3>
                ) : (
                  <>
                    <h3 className="font-section-header mb-2">
                      Connect Rydoo Chatbot with 100+apps, and 1000s of
                      automations instantly. No coding
                    </h3>
                  </>
                )
              }
              footer={
                <>
                  Also available CRM, MDM Apps, Project Management, Accounting,
                  Email Management, SMS Management, Notifications, and
                  Collaboration Apps.
                </>
              }
              apps={integrationsApps}
            />

            <section className="float-left blog-main-container-chat-bot">
              <div className="container">
                <div className="usecase_blog_container">
                  <h3 class="font-section-sub-header-small-bold-v2">
                    Must Read Blogs
                  </h3>
                  <div className="usecase_bottom_blog">
                    <ul>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service"
                        >
                          Conversational AI Chatbot for employee service
                          automation 
                        </a>
                      </li>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/5-benefits-of-introducing-hr-bots-to-support-remote-workers"
                        >
                          5 Benefits of Introducing HR Bots to Support Remote
                          Workers
                        </a>
                      </li>
                      <li>
                        <a
                          className="blog_usecase_link font-section-normal-text"
                          href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                        >
                          What Is Conversational AI? A Guide to Conversational
                          AI Chatbots
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const tabsForHrIt = [
  {
    header: "Rydoo Bot",
    hasZeroPaddedDesktopImage: true,
    hasZeroPaddedDesktopImageRight: true,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            Build Rydoo self-service chatbot in minutes
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Use workativ’s chatbot builder to add, remove, or optimize new
            topics for Rydoo chatbot easily based on your requirement. Use
            our no-code canvas to create engaging employee experiences faster in
            minutes.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Go digital with a chatbot to deliver employee support – faster,
            smarter, and easier.
          </p>
        </div>
        <div className="support_content_right_container align-self-end pb-0">
          <img src={capabilities_1} alt="Rydoo Bot"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Build Rydoo self-service chatbot in minutes
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Use workativ’s chatbot builder to add, remove, or optimize new topics
          for Rydoo chatbot easily based on your requirement. Use our no-code
          canvas to create engaging employee experiences faster in minutes.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Go digital with a chatbot to deliver employee support – faster,
          smarter, and easier.
        </p>
        <img src={capabilities_1} alt="Rydoo Bot"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Knowledge AI",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Leverage the power of Large Language Model (LLM) and Generative AI
            to super charge your knowledge base and respond to user queries in
            seconds.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_2} alt="knowledge ai"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
        <p className="font-section-normal-text-testimonials  line-height-18 mb-0 pb-0">
          Leverage the power of Large Language Model (LLM) and Generative AI to
          super charge your knowledge base and respond to user queries in
          seconds.
        </p>
        <img
          src={capabilities_2}
          alt="knowledge ai"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "App Workflow Automation",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClick slides={slides} />
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClickMobile slides={slides} />{" "}
      </React.Fragment>
    ),
  },
  {
    header: "Shared Live Inbox",
    hasGridLayoutLargeSmall: true,
    isComingSoon: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Shared Inbox with live chat{" "}
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "24px" }}>
              *
            </sup>
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Engage users with confidence using shared inbox for live chat, bot
            transfers, actions, and much more.
          </p>
          <span className="position-absolute bottom-0 font-section-small-signup-form">
            <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
              *
            </sup>
            Coming soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_4} alt="shared inbox"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        <h3 className="font-section-normal-text-medium-link">
          Shared Inbox with live chat{" "}
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "16px" }}>
            *
          </sup>
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Engage users with confidence using shared inbox for live chat, bot
          transfers, actions, and much more.
        </p>
        <span className="font-section-small-signup-form">
          <sup style={{ top: "0em ", color: "#E05959", fontSize: "15px" }}>
            *
          </sup>
          Coming soon
        </span>

        <img
          src={capabilities_4}
          alt="shared inbox"
          className="mobile-image-with-box-shadow"
        ></img>
      </React.Fragment>
    ),
  },
  {
    header: "Bot Marketplace",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Add pre-built tasks to Rydoo Chatbot from our marketplace
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ comes with over 1000+ pre-built actions for popular apps
            like Access Management, Payroll, Time Management, ITSM, Document
            Management, Office 365, Jira and so on including Rydoo actions,
            that is ready to use from our app workflow marketplace. No coding.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            Just download, connect and go live instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_5} alt="Bot Marketplace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Add pre-built tasks to Rydoo Chatbot from our marketplace
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ comes with over 1000+ pre-built actions for popular apps like
          Access Management, Payroll, Time Management, ITSM, Document
          Management, Office 365, Jira and so on including Rydoo actions,
          that is ready to use from our app workflow marketplace. No coding.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          Just download, connect and go live instantly.
        </p>
        <img src={capabilities_5} alt="Bot Marketplace"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Omnichannel",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Deploy Rydoo chatbot on your Slack, Teams, or a Chat Widget in
            few clicks
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Take your chatbot to where your employees are—on Slack or Teams.
            Give your employees a modern chatbot that resolves issues faster and
            easier.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
            {" "}
            Turn your Teams and Slack into self-service helpdesk channel for
            employees.
          </p>
          <div className="icons_container_onboarding mt-0">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_6} alt="Omnichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Deploy Rydoo chatbot on your Slack, Teams, or a Chat Widget in few
          clicks
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Take your chatbot to where your employees are—on Slack or Teams. Give
          your employees a modern chatbot that resolves issues faster and
          easier.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
          {" "}
          Turn your Teams and Slack into self-service helpdesk channel for
          employees.
        </p>
        <div className="icons_container_onboarding mt-2 mb-4">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={capabilities_6} alt="Omnichannel"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Agent Handover",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Transfers
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ offers seamless{" "}
            <b className="color-black-resource font-section-normal-text-testimonials-medium">
              ‘handover’
            </b>{" "}
            of chatbot to HR Teams with complete context and user conversation
            history so your HR can pick up right where the bot left, avoiding
            any user frustration – but most importantly solving employee issues
            right away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_7} alt="Agent Handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Live Transfers
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ offers seamless{" "}
          <b className="color-black-resource font-section-normal-text-testimonials-medium">
            ‘handover’
          </b>{" "}
          of chatbot to HR Teams with complete context and user conversation
          history so your HR can pick up right where the bot left, avoiding any
          user frustration – but most importantly solving employee issues right
          away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Make your MS Teams a live agent channel instantly.
        </p>
        <img src={capabilities_7} alt="Agent Handover"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Approvals",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Automating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_8} alt="Approvals"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Approve your chatbot requests in seconds
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Automating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>
        <img src={capabilities_8} alt="Approvals"></img>
      </React.Fragment>
    ),
  },

  {
    header: "Context Switching",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Context Switching
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            User conversations are never singular. A typical conversation may
            involve more than one context and goal, and even more confusing when
            the user decides to change the request halfway. Workativ handles
            context switching swiftly without loosing users.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_9} alt="context switching"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Context Switching
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          User conversations are never singular. A typical conversation may
          involve more than one context and goal, and even more confusing when
          the user decides to change the request halfway. Workativ handles
          context switching swiftly without loosing users.
        </p>
        <img src={capabilities_9} alt="context switching"></img>
      </>
    ),
  },
  {
    header: "Sensitive Data Handling",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Sensitive Data Handling
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            It gives the ability for the admin to securely handle the user
            information provided to the bot and resolve the user query without
            storing or displaying the user information.{" "}
            <span className="font-section-normal-text-testimonials-medium color-black">
              Example:
            </span>{" "}
            User wants to reset their password and provides the new password to
            the chatbot, and the bot will update the password and will not store
            or display.
          </p>
        </div>
        <div className="support_content_right_container">
          <img src={capabilities_10} alt="sensitive data handling"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Sensitive Data Handling
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          It gives the ability for the admin to securely handle the user
          information provided to the bot and resolve the user query without
          storing or displaying the user information.{" "}
          <span className="font-section-normal-text-testimonials-medium color-black">
            Example:
          </span>{" "}
          User wants to reset their password and provides the new password to
          the chatbot, and the bot will update the password and will not store
          or display.
        </p>
        <img src={capabilities_10} alt="sensitive data handling"></img>
      </>
    ),
  },
  {
    header: "Dynamic Notifications",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 ">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Bot notifications can be setup using app triggers that can initiate
            the bot to take an action. e.g password expiry, employee onboarding
            etc. Endless possibilities.
          </p>
        </div>
        <div className="support_content_right_container pb-0">
          <img src={capabilities_11} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be setup using app triggers that can initiate
          the bot to take an action. e.g password expiry, employee onboarding
          etc. Endless possibilities.
        </p>
        <img src={capabilities_11} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analytics & logs - deep dive on chatbot interactions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            Great dashboards are useless, said no-one ever. Monitor your chatbot
            performance easily from a single pane of glass.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Review chatbot usage, bot sessions, user queries, live status and
            much more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_12} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Analytics & logs - deep dive on chatbot interactions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  pb-1">
          Great dashboards are useless, said no-one ever. Monitor your chatbot
          performance easily from a single pane of glass.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18  pb-0">
          Review chatbot usage, bot sessions, user queries, live status and much
          more.
        </p>
        <img src={capabilities_12} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLargeSmall: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using Chat History, you can quickly comb through conversations to
            possibly spot unsuccessful conversation and improve chatbot
            effectiveness. Analyze chatbot and user conversations, user
            experience, and end-user feedback.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={capabilities_13} alt="chat history"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using Chat History, you can quickly comb through conversations to
          possibly spot unsuccessful conversation and improve chatbot
          effectiveness. Analyze chatbot and user conversations, user
          experience, and end-user feedback.
        </p>
        <img src={capabilities_13} alt="chat history"></img>
      </>
    ),
  },
];
const tabsForHrItImages = [
  capabilities_1,
  capabilities_2,
  capabilities_4,
  capabilities_5,
  capabilities_6,
  capabilities_7,
  capabilities_8,
  capabilities_9,
  capabilities_10,
  capabilities_11,
  capabilities_12,
  capabilities_13,
];

const tabsForOnBoarding = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can create a new bot or download pre-built Rydoo bot from
            marketplace.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          You can create a new bot or download pre-built Rydoo bot from
          marketplace.
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download Rydoo Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Download Rydoo Bot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download Rydoo bot in one-click, add new or edit existing
            support topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Download Rydoo Bot</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download Rydoo bot in one-click, add new or edit existing
          support topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly. No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly. No coding.
        </p>

        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];

const tabsForOnBoardingImages = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
];

export const slides = [
  {
    image: capabilities_3_1,
    title: "Expense Claims",
    tabFor: "vertical-tab-one",
    alt: "expense claims chatbot",
  },
  {
    image: capabilities_3_2,
    title: "Reimbursements",
    tabFor: "vertical-tab-two",
    alt: "Reimbursements chatbot",
  },
  {
    image: capabilities_3_3,
    title: "Payment Enquires",
    tabFor: "vertical-tab-three",
    alt: "Payment Enquires chatbot",
  },

  {
    image: capabilities_3_4,
    title: "Policy Searches",
    tabFor: "vertical-tab-four",
    alt: "policy searches chatbot",
  },
  {
    image: capabilities_3_5,
    title: "Onboarding",
    tabFor: "vertical-tab-five",
    alt: "onboarding chatbot",
  },
  {
    image: capabilities_3_6,
    title: "Offboarding",
    tabFor: "vertical-tab-six",
    alt: "offboarding chatbot",
  },
];

export function AdHomePage_November() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left ad_home_page_main_container">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-4">
          Scale your support with Gen AI powered chatbot with auto-resolution
        </h4>
        <div className="ad_flex_container_assist">
          <div className="ad_container_one_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce your Mean Time To Resolution (to seconds)
            </h5>
            <img src={ad_one} alt="Mean Time To Resolution"></img>
          </div>
          <div className="ad_container_two_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Reduce emails and call volumes with instant auto-resolution
            </h5>
            <img src={ad_two} alt="emails and call volumes"></img>
          </div>
          <div className="ad_container_three_assist">
            <h5 className="font-section-normal-text-medium text-align-center mb-5">
              Scale your support instantly with ease
            </h5>
            <img src={ad_three} alt="support instantly with ease"></img>
          </div>
        </div>
        {isSmall ? (
          <span className="ad_mobile_right_arrow">
            <RightArrowIconAssistants />
          </span>
        ) : null}
      </div>
    </section>
  );
}
export function tabSliderInit(tabData) {
  return { activeIndex: 0, totalTabs: tabData.length };
}
export const tabSliderReducerWithLoop = (state, action) => {
  // console.log("DOING ::: reducer ", state, action)
  switch (action.type) {
    case "to": {
      // console.log("DOING ::: reducer :: 1", state, action, action.toTab < state.totalTabs, action.toTab >= 0)
      if (action.toTab < state.totalTabs && action.toTab >= 0) {
        const toReturn = { ...state, activeIndex: action.toTab };
        // console.log("DOING ::: reducer :: 2", toReturn)
        return toReturn;
      }
      // console.log("DOING ::: reducer :: 3");
      return state;
    }
    case "next":
      if (state.activeIndex == state.totalTabs - 1) {
        return { ...state, activeIndex: 0 };
      }

      if (state.activeIndex < state.totalTabs - 1) {
        return { ...state, activeIndex: state.activeIndex + 1 };
      }
      return state;
    case "prev":
      if (state.activeIndex == 0) {
        return { ...state, activeIndex: state.totalTabs - 1 };
      }
      if (state.activeIndex < state.totalTabs && state.activeIndex > 0) {
        return { ...state, activeIndex: state.activeIndex - 1 };
      }
      return state;
    case "reset":
      return { ...state, activeIndex: 0 };
    default:
      throw new Error("Invalid action");
  }
};
export function SectionWithImagesWithExternalClick({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const altTag = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);
  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  // const allText = slides.map((slide) => slide.text);
  return (
    <>
      <div className="support_content_left_container it_support_left_content_container">
        {" "}
        <h3 className="font-section-normal-text-medium-link">
          Rydoo chatbot + task automation in a single platform
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Most chatbots only automate conversations. Only workativ platform
          provides one-click integration of Rydoo with 3rd party apps and tasks
          to automate repetitive HR enquires & requests such as&nbsp;
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
          >
            {" "}
            expense claims
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
          >
            reimbursements
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
          >
            payment enquires
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
          >
            policy searches
          </span>
          ,
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
          >
            {" "}
            employee onboarding
          </span>
          /{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
          >
            {" "}
            offboarding
          </span>{" "}
          and much more.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
          Help you employees be more productive.
        </p>
      </div>
      <div className="right_container_tab_slider_it_suuport">
        <div className="left_cards_tab_appworkflow">
          <h5 className="font-name-smaller-text text-align-center mb-0">
            Click below to view<br></br> different usecase
          </h5>

          <img src={workflow_arrow} className="workflow_arrow_svg"></img>
          <div className="cards_all_main_container_app_workflow">
            {allTitles.map((title, i) => (
              <div
                className={`cards_single_main_container_app_workflow cursor-pointer ${
                  i === currentTabState.activeIndex
                    ? "selected_tab_workflow"
                    : ""
                }`}
                onClick={() => dispatchTabAction({ type: "to", toTab: i })}
              >
                <h5 className="font-name-smaller-text mb-0">{title}</h5>{" "}
                <img src={arrow}></img>
              </div>
            ))}

            {/* <div className="cards_single_main_container_app_workflow selected_tab_workflow">
              <h5 className="font-name-smaller-text mb-0">Reset Password</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">User Provisioning</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Access Management</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Asset Requests</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Onboarding</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow border-bottom-none">
              <h5 className="font-name-smaller-text mb-0">Offboarding</h5>
              <img src={arrow}></img>
            </div> */}
          </div>
        </div>
        <div className="right_cards_tab_appworkflow">
          <img classname="w-100" src={imageToDisplay} alt={altTag}></img>
        </div>
      </div>
    </>
  );
}
export function SectionWithImagesWithExternalClickMobile({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const textToDisplay = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);

  const allImages = slides.map((slide) => slide.image);
  usePreloadImages_November(allImages);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // const total = slides.length; // the length of the slide array
  // const enabled = true; // pauses/disables the player
  // const useLoaded = false; // this allows for delayed loads like images or embeds
  // // const speed = 10000; // speed in milliseconds to show each slide
  // const loop = true;

  // const [offset, setOffset] = React.useState(0);
  // const [items, setItems] = React.useState([]);

  function incrementOffset() {
    dispatchTabAction({ type: "next" });
  }

  function decrementOffset() {
    dispatchTabAction({ type: "prev" });
  }
  return (
    <>
      <h3 className="font-section-normal-text-medium-link">
        Rydoo chatbot + task automation in a single platform
      </h3>
      <p className="font-section-normal-text-testimonials line-height-18 pb-1">
        Most chatbots only automate conversations. Only workativ platform
        provides one-click integration of Rydoo with 3rd party apps and tasks to
        automate repetitive HR enquires & requests such as&nbsp;
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
        >
          {" "}
          expense claims
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
        >
          reimbursements
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
        >
          payment enquires
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
        >
          policy searches
        </span>
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 4 })}
        >
          {" "}
          employee onboarding
        </span>
        /
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
        >
          {" "}
          offboarding
        </span>{" "}
        and much more.
      </p>
      <p className="font-section-normal-text-testimonials line-height-18 pb-0 color-light-black">
        Help you employees be more productive.
      </p>

      <div className="right_container_tab_slider_it_suuport" {...handlers}>
        <img
          className="w-75 m-auto"
          src={imageToDisplay}
          alt={textToDisplay}
        ></img>

        <div className="it_app_workflow_mobile_tab_slider">
          <img
            src={arrow_round_mobile}
            className="support_tab_left_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "prev" })}
          ></img>
          <h5 className="font-section-normal-text-medium  mb-0">
            {textToDisplay}
          </h5>
          <img
            src={arrow_round_mobile}
            className="support_tab_right_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "next" })}
          ></img>
        </div>
      </div>
    </>
  );
}
